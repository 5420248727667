<template>
  <div class="das_top_cont">
    <div class="history_box">
      <div class="accountright_cont_box">
        <div class="flex_center_between_box">
          <h2 class="history_box-head">Privacy Policy</h2>
        </div>
        <h3 class="history_box-h3">
          Our Privacy Policy is designed to assist you in understanding how we
          collect and use the personal information you provide to us and to
          assist you in making informed decisions when using our site and our
          products and services.
        </h3>

        <div>
          <div class="item_title">
            <div class="item_cont_txt-head">
              What Information Do We Collect?
            </div>

            <div class="item_cont_txt" style="font-family: Arial Bold">
              1. Personal Informat You Choose to Provide
            </div>
            <div
              class="item_cont_txt"
              style="font-family: Arial Bold; padding-left: 20px"
            >
              Registration Informa
            </div>
            <h3 class="history_box-h4" style="padding-left: 20px">
              Our Privacy Policy is designed to assist you in understanding how
              we collect and use the personal information you provide to us and
              to assist you in making informed decisions when using our site and
              our products and services.
            </h3>
            <div
              class="item_cont_txt"
              style="font-family: Arial Bold; padding-left: 20px"
            >
              Email Information.
            </div>
            <h3 class="history_box-h4" style="padding-left: 20px">
              If you choose to correspond with us through email, we may retain
              the content of your email messages together with your email
              address and our responses.
            </h3>
            <div class="item_cont_txt" style="font-family: Arial Bold">
              2. Website Use Information
            </div>

            <h3 class="history_box-h4" style="padding-left: 20px">
              Similar to other commercial websites, our website utilizes a
              standard technology called "cookies" (see explanation below, "What
              Are Cookies?") and Web server logs to collect information about
              how our website is used. Information gathered through cookies and
              Web server logs may include the date and time of visits, the pages
              viewed, time spent at our website, and the Websites visited just
              before and just after our website. We, our advertisers and ad
              serving companies may also use small technology or pieces of code
              to determine which advertisements and promotions users have seen
              and how users responded to them.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">
              How Do We Use the Information That You Provide to Us?
            </div>

            <h3 class="history_box-h4">
              Broadly speaking, we use personal information for purposes of
              administering and expanding our business activities, providing
              customer service and making available other products and services
              to our customers and prospective customers. Occasionally, we may
              also use the information we collect to notify you about important
              changes to our website, new services and special offers we think
              you will find valuable. You may notify us at any time if you do
              not wish to receive these updates and/or offers by emailing us at
              request@CSIbizInfo.com
            </h3>
          </div>

          <div class="item_title">
            <div class="item_cont_txt-head">What Are Cookies?</div>

            <h3 class="history_box-h4">
              A cookie is a very small text document, which often includes an
              anonymous unique identifier. When you visit a website, that site's
              computer asks your computer for permission to store this file in a
              part of your hard drive specifically designated for cookies. Each
              Website can send its own cookie to your browser if your browser's
              preferences allow it, but (to protect your privacy) your browser
              only permits a Website to access the cookies it has already sent
              to you, not the cookies sent to you by other sites. Some of our
              business partners (e.g., advertisers) use cookies that originate
              from their sites. We have no access or control over those cookies.
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">
              How Do We Use Information We Collect from Cookies?
            </div>

            <h3 class="history_box-h4" style="padding-left: 20px">
              As you use our website, the site uses its cookies to differentiate
              you from other users. In some cases, we also use cookies to
              prevent you from seeing unnecessary advertisements or requiring
              you to log in more than once for security reasons. Cookies, in
              conjunction with our Web server's log files, allow us to calculate
              the aggregate number of people visiting our website and which
              parts of the site are most popular. This helps us gather feedback
              in order to constantly improve our website and better serve our
              customers. Cookies do not allow us to gather any personal
              information about you and we do not generally store any personal
              information that you provided to us in your cookies.
            </h3>
            <div
              class="item_cont_txt"
            
              style="font-family: DMSans Bold; padding-left: 20px"
            >
              Sharing Information with Third Parties
            </div>
            <h3 class="history_box-h4" style="padding-left: 20px">
              We may enter into alliances, partnerships or other business
              arrangements with third parties who may be given access to
              personal information including your name, address, telephone
              number and email for the purpose of providing you information
              regarding products and services that we think will be of interest
              to you. In connection with alliances, partnerships or
              arrangements, we may also provide certain information to third
              parties if we have determined that the information will be used in
              a responsible manner by a responsible third party. For example,
              some of our partners operate stores or provide services on our
              site, while others power offerings developed by us for your use.
              We also use third parties to facilitate our business, including,
              but not limited to, sending email and processing credit card
              payments. In connection with these offerings and business
              operations, our partners and other third parties may have access
              to your personal information for use in connection with the
              business activities. As we develop our business, we may buy or
              sell assets or business offerings. Customer, email, and visitor
              information is generally one of the transferred business assets in
              these types of transactions. We may also transfer such information
              in the course of corporate divestitures, mergers, or any
              dissolution.
            </h3>
            <div
              class="item_cont_txt"
              style="font-family: Arial Bold; padding-left: 20px"
            >
              Notice of New Services and Changes
            </div>

            <h3 class="history_box-h4" style="padding-left: 20px">
              Occasionally, we may also use the information we collect to notify
              you about important changes to our website, new services and
              special offers we think you will find valuable. As our customer,
              you will be given the opportunity to notify us if you would not
              like to receive these offers, by sending us an email request at
              request@CSIbizInfo.com
            </h3>
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">
              How Do We Secure Information Transmissions?
            </div>

            <h3 class="history_box-h4">
              Email is not recognized as a secure medium of communication. For
              this reason, we request that you do not send private information
              to us by email. Some of the information you may enter on our
              website may be transmitted securely via Secure Sockets Layer SSL,
              128-bit encryption services. Pages utilizing this technology will
              have URLs that start with HTTPS instead of HTTP. Please contact
              request@CSIbizInfo.com if you have any questions or concerns.
            </h3>
          </div>
           <div class="item_title">
            <div class="item_cont_txt-head">
             How Can You Access and Correct Your Information?
            </div>

            <h3 class="history_box-h4" >
           You may request access to all your personally identifiable information that we collect online and maintain in our database by emailing request@CSIbizInfo.com
            </h3>
            <div
              class="item_cont_txt"
              style=" font-family: DMSans Bold; padding-left: 20px"
            >
           Certain Disclosures
            </div>
            <h3 class="history_box-h4" style="padding-left: 20px">
            We may disclose your personal information if required to do so by law or subpoena or if we believe that such action is necessary to (a) conform to the law or comply with legal process served on us or affiliated parties; (b) protect and defend our rights and property, our site, the users of our site, and/or our affiliated parties; (c) act under circumstances to protect the safety of users of our site, us, or third parties.
            </h3>
        
          </div>
          <div class="item_title">
            <div class="item_cont_txt-head">
           What About Other Websites Linked to Our Website?
            </div>

            <h3 class="history_box-h4" >
         We are not responsible for the practices employed by Websites linked to or from our website nor the information or content contained therein. Often links to other Websites are provided solely as pointers to information on topics that may be useful to the users of our website.Please remember that when you use a link to go from our Website to another Website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other Website, including Websites which have a link on our Website, is subject to that Website's own rules and policies. Please read those rules and policies before proceeding.
            </h3>
            <div
              class="item_cont_txt"
              style=" font-family: 'DMSans Bold'; padding-left: 20px"
            >
        Your Consent
            </div>
            <h3 class="history_box-h4" style="padding-left: 20px">
                By using our website, you consent to our collection and use of your personal information as described in this Privacy Policy. If we change our privacy policies and procedures, we will post those changes on our website to keep you aware of what information we collect, how we use it and under what circumstances we may disclose it to keep you aware of what information we collect, how we use it and under what circumstances we may disclose it
            </h3>
        
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeNames: "",
      attentionDialog: false, //弹框
    };
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
<style scoped>
.title {
  font-family: 'DMSans Bold';
  margin-top: 1.5714285714285714rem;
  margin-bottom: 1.4285714285714286rem;
}
.el-collapse {
  border: none !important;
  font-family: "Arial";
}
.item_title /deep/ .el-collapse-item__header {
  font-size: 1.4285714285714286rem;
  color: #022955;
  font-family: "Arial";
  /* margin: 0.7142857142857143rem 0; */
  height: 1.4285714285714286rem;
  background-color: #f5f5f5;
  border: none !important;
}
.item_title {
  padding: 1.4285714285714286rem;
  background-color: #f5f5f5;
  margin-bottom: 0.7142857142857143rem;
}
.item_title.el-collapse-item.is-active:last-child
  /deep/
  .el-collapse-item__wrap,
.item_title:last-child /deep/ .el-collapse-item__header {
  border-width: 0;
  border: none !important;
}
.item_title /deep/ .el-collapse-item__header.is-active {
  border-bottom-color: transparent;
}
.item_cont_title {
  /* font-family: "Arial Bold"; */
  font-family: "Arial";
  /* margin-bottom: 0.7142857142857143rem; */
  color: #022955;
}
.item_cont_txt {
  font-size: 1.2857142857142858rem;
  color: #1a2332;
  background-color: #f5f5f5;
  padding-top: 1.1428571428571428rem;
}
.title_txt img {
  width: 1.4285714285714286rem;
  margin-right: 0.5714285714285714rem;
}
.item_title /deep/.el-collapse-item__content {
  padding-bottom: 0;
}

/* .item_title /deep/ .el-collapse-item__arrow {
    width: 1.7142857142857142rem;
    height: 1.7142857142857142rem;
    background: #ffffff;
    border-radius: 0.5714285714285714rem;
    border: 0.07142857142857142rem solid #e7eaed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 0.6428571428571429rem;
} */
/* .item_title /deep/ .el-collapse-item__arrow::before {
    content: '';
}
*/
.item_title /deep/ .el-collapse-item__arrow {
  transform: rotate(90deg);
}

.item_title /deep/ .el-collapse-item__arrow.is-active {
  transform: rotate(-90deg);
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}
.link {
  color: #1290c9;
  text-decoration: underline;
}
.head-text {
  font-family: 'DMSans Bold';
  font-size: 1.4285714285714286rem !important;
  text-align: left;
  font-style: normal;
  color: #1a2332;
  line-height: 1.6428571428571428rem;
}
.icon-time {
  font-size: 1.1428571428571428rem;
  line-height: 1.6428571428571428rem;
  color: #999999;
}
.message-right {
  margin-left: 1.4285714285714286rem;
}

ul {
  background-color: #f5f5f5;
}
/deep/ .el-collapse-item__wrap {
  border: none !important;
}
.history_box-h3 {
  font-size: 1.2857142857142858rem;
  color: #666666;
  line-height: 1.8571428571428572rem;
  margin: 1.4285714285714286rem 0rem;
}
.history_box-h4 {
  font-size: 1.2857142857142858rem;
  color: #666666;
  line-height: 1.8571428571428572rem;
  margin-top: 0.7142857142857143rem;
}
.item_cont_txt-head {
  font-weight: normal;
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-family: 'DMSans Bold';
}
@media screen and (max-width: 1441px) {
.item_cont_txt,.history_box-h4{
  font-size: 12px;
}
}
</style>